import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';

const features = [
  {
    name: 'Cookie Dropping',
  },
  {
    name: 'Extension malveillante',
  },
  {
    name: 'Retargeting non autorisé',
  },
  {
    name: 'Post impression',
  },
  {
    name: 'Faux numéro de commande',
  },
  {
    name: 'Duplications des commandes',
  },
  {
    name: 'Erreurs de tracking',
  },
  {
    name: 'Transactions en magasin',
  },
  {
    name: 'Bon de réduction expiré',
  },
  {
    name: 'Achat de la marque',
  },
];

export default function Example() {
  return (
    <div className="bg-gray-50 sm:pt-72">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-10">
        <div>
          <p className="mt-2 text-3xl font-title font-extrabold text-gray-900">
            De plus en plus de fraudes publicitaires en ligne
          </p>
        </div>
        <div className="mt-12 lg:mt-6 lg:col-span-2">
          <dl className="sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-5 sm:grid-flow-col sm:gap-x-6 sm:gap-y-6">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import productScreenshot from '../../../images/fraud-screenshot.png';
import PageLayout from '../../../layouts/Page';
import Testimonials from '../../../components/Testimonials';
import FraudsList from './FraudsList';
import SmartAlgorithms from './SmartAlgorithms';
import Vouchers from './Vouchers';
import ThankYouSmallLogo from '../../../images/small_logo.svg';

const Page = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Détectez les fraudes en affiliation avec TY Affiliate</title>
        <meta
          name="description"
          content="En affiliation, plus de 27% des commissions sont frauduleuses*. Mettez fin à la fraude au sein de votre programme avec TY Affiliate, solution anti-fraude en affiliation."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-title text-3xl font-extrabold tracking-tight text-green-brand">
              <div className="relative flex flex-column justify-center items-center">
                <img
                  className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                  src={ThankYouSmallLogo}
                  alt="Thank-You Analytics Logo"
                />
                <span>Affiliate</span>
              </div>
            </h2>
            <p className="mt-4 max-w-6xl mx-auto font-title font-extrabold text-gray-900 text-2xl sm:tracking-tight lg:text-5xl">
              Détection des fraudes en affiliation
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              En affiliation, plus de 27% des commissions sont frauduleuses*. Mettez fin à la fraude
              au sein de votre programme avec TY Affiliate, solution anti-fraude en affiliation.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white sm:-mb-80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <img className="" src={productScreenshot} alt="Fraudes" />
        </div>
      </div>

      <FraudsList />
      <SmartAlgorithms />
      <Vouchers />
      <div className="bg-gray-50">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-4xl font-title font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Vous souhaitez en savoir plus ?</span>
            <span className="block text-green-brand">Faites une demande de démo</span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              to="/contact"
              className="flex items-center justify-center bg-green-brand px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-green-brandHover"
            >
              Demande de démo
            </Link>
          </div>
        </div>
      </div>
      <Testimonials />
      <div className="bg-white">
        <div className="relative py-4 max-w-xl lg:max-w-7xl mx-auto">
          <p className="text-right text-sm text-gray-500">
            * étude menée en 2020 sur un panel de 10 annonceurs sur le marché français.
          </p>
        </div>
      </div>
    </PageLayout>
  );
};

export default Page;

import React from 'react';

import machineLearningIllu from '../../../images/illu-machine-learning.svg';

export default function Example() {
  return (
    <div className="relative mt-12 max-w-xl lg:max-w-7xl mx-auto sm:mt-16 lg:mt-24">
      <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div className="lg:col-start-1">
          <h3 className="text-2xl font-extrabold text-green-brand font-title tracking-tight sm:text-3xl">
            Découvrez nos algorithmes intelligents
          </h3>
          <p className="mt-3 text-lg text-gray-500">
            Pour chaque type de fraude nous avons développé un algorithme qui
            vient étudier tous les subtiles indices laissés par l'affilié
            suspect. Chaque comportement douteux est ainsi détecté
            automatiquement puis des actions viennent se mettre en place : mise
            en quarantaine de l'affilié, diminution de la commission,
            commissions reportées ou bloquées.
          </p>
        </div>

        <div className="mt-10 relative lg:mt-0 lg:col-start-2">
          <img className="relative mx-auto" src={machineLearningIllu} alt="" />
        </div>
      </div>
    </div>
  );
}

import React from 'react';

import screenshot from '../../../images/screenshot-frauds-voucher.jpg';

export default function Example() {
  return (
    <div className="bg-white">
      <div className="relative py-12 max-w-xl lg:max-w-7xl mx-auto sm:py-16 lg:py-24">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-3 lg:gap-16 lg:items-center">
          <div className="lg:col-start-3 ">
            <h3 className="text-2xl font-extrabold text-green-brand font-title tracking-tight sm:text-3xl">
              Maîtriser vos codes promo
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              En affiliation il est très important d'identifier l'usage des codes promos par les
              internautes. Avec TY Affiliate, identifiez les codes promo CRM qui ne devraient pas
              être diffusés en affiliation mais aussi les codes promo expirés.
            </p>
          </div>

          <div className="mt-10 relative lg:mt-0 lg:col-start-1 col-span-2">
            <img
              className="relative rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
              src={screenshot}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
